import Timeline from "../components/timeline"
import Navbar from "../components/Navbar"
const Education = () => {
    return (
        <div>
            <Navbar />
            <Timeline />
        </div>
    )
}

export default Education